<template>
	<div id="mainpage" class="home relative flex flex-col min-h-screen">

		<!-- <modal name="promo-popup" height="auto" width="100%">
			<div class="relative">
				<button @click="$modal.hide('promo-popup')" class="absolute bg-white rounded-full" style="left: 50%; transform: translateX(-50%); top: -12px">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z"/></svg>
				</button>
				<a href="/pdf/CHRISTMAS-24-MENU.pdf" target="_blank">
					<img class="mx-auto rounded-md shadow-lg" src="@/assets/images/promo-popup.jpg"/>
				</a>
			</div>
		</modal> -->

		<SiteHeader />

		<div class="section mainbanner-section lg:min-h-screen lg:max-h-[1080px] bg-black">
			<carousel class="header-slider" :dots="true" :nav="false" :loop="false" :autoplay="true" :autoHeight="false"
				:autoplaySpeed="1000" :autoplayTimeout="5000" :autoplayHoverPause="false" :touchDrag="false" :mouseDrag="false"
				:items="1">
				<!-- :animateOut="'fadeOut'" -->
				<div class="lg:min-h-screen lg:max-h-[1080px] bg-cover bg-center bg-no-repeat"
					:style="'background-image: url(' + require(`@/assets/images/mainbanner-bg01.jpg`) + ');'">
					<div class="pt-44 sm:pt-32 lg:pt-58 container pb-16 text-white">
						<div class="space-y-5">
							<div class="">
								<div class="font-gothamlight lg:text-7xl text-lg leading-tight">The Perfect Place To</div>
								<div class="lg:leading-none lg:text-23xl text-5xl font-bold leading-normal">Chill & Relax</div>
								<div class="lg:mb-8 lg:text-22xl mb-4 text-3xl leading-none">Happy Hour All Night!</div>
							</div>
							<div class="inline-block space-y-5">
								<div class="lg:text-5xl font-gothambook flex items-center">
									<div class="flex-none">Check Our Menu</div>
									<!-- <div class="w-full ml-6 border-t-2 border-white"></div> -->
								</div>
								<div class="lg:text-lg flex flex-wrap text-black">
									<a href="/pdf/tbc-full-menu.pdf" target="_blank"
										class="px-6 lg:px-14 py-3 mr-4 mb-4  lg:py-4 rounded-full uppercase sm:tracking-[.2em] duration-200 bg-white hover:bg-siteYellow">All
										Day Menu</a>
								</div>
							</div>
						</div>
					</div>
				</div>

			</carousel>
		</div>

		<div class="section aboutus-section">
			<div class="lg:py-16 container py-8">
				<div class="lg:grid-cols-2 gap-x-16 gap-y-8 grid items-center">
					<img class="mx-auto rounded-lg" src="@/assets/images/aboutus-img01.jpg" alt="About Us" width="592"
						height="343">

					<div class="space-y-8">
						<div class="inline-block">
							<div class="lg:text-lg flex items-center">
								<div class="flex-none tracking-[.2em]">About Us</div>
								<div class="w-full ml-2 border-t-2 border-black"></div>
							</div>
							<div class="flex space-x-4 text-black">
								<div class="lg:text-6xl text-2xl">The Carpenters Bar</div>
							</div>
						</div>
						<p class="lg:text-left leading-loose text-justify">Established in December 2018, The Carpenters Bar is your
							classic roadhouse tavern located in the northernmost part of Singapore, in a rural industrial estate,
							almost becoming more of a speakeasy than a bistro. Prominently along Admiralty Road West, with a secluded
							entrance via Woodlands Sector 2, we have sent our patrons through a wild maze before reaching us. Well,
							that’s all part of the adventure!</p>
					</div>

					<p class="lg:text-left lg:col-span-2 leading-loose text-justify">Come in and immerse yourself in the
						rough-and-tumble vibes of travel dating back to as early as the 1900s, when roadhouses were synonymous with
						road trips to provide everyone with big, hearty meals, good music, and casual laid-back rustic aesthetics.
					</p>

					<p class="lg:text-left lg:col-span-2 leading-loose text-justify">Almost everybody knows everybody here, and
						you know it's ‘home’ with the familiar mingling and socialising for knocking back pints or over a game of
						pool. Your watering hole just got better. So, bring your whole family – and your pets too - to join in the
						fun!</p>

					<div class="lg:pt-8 lg:grid-cols-4 lg:col-span-2 lg:gap-8 grid grid-cols-2 gap-4 text-center">
						<div
							class="group lg:py-8 odd:bg-siteYellow even:bg-black even:text-white lg:space-y-5 order-1 px-4 py-6 space-y-4 rounded-lg">
							<div class="lg:pt-6 pt-2">
								<img class="lg:h-28 group-even:invert w-auto h-20 mx-auto" src="@/assets/images/aboutus-img02.png"
									alt="Alfresco Dining" width="112" height="112">
							</div>
							<div class="lg:text-2xl">Alfresco Dining</div>
						</div>
						<div
							class="group lg:py-8 odd:bg-siteYellow even:bg-black even:text-white lg:space-y-5 order-2 px-4 py-6 space-y-4 rounded-lg">
							<div class="lg:pt-6 pt-2">
								<img class="lg:h-28 group-even:invert w-auto h-20 mx-auto" src="@/assets/images/aboutus-img03.png"
									alt="Family Friendly" width="107" height="112">
							</div>
							<div class="lg:text-2xl">Family Friendly</div>
						</div>
						<div
							class="group lg:py-8 odd:bg-siteYellow even:bg-black even:text-white lg:space-y-5 lg:order-3 order-4 px-4 py-6 space-y-4 rounded-lg">
							<div class="lg:pt-6 pt-2">
								<img class="lg:h-28 group-even:invert w-auto h-20 mx-auto" src="@/assets/images/aboutus-img04.png"
									alt="Pet Friendly" width="129" height="112">
							</div>
							<div class="lg:text-2xl">Pet Friendly</div>
						</div>
						<div
							class="group lg:py-8 odd:bg-siteYellow even:bg-black even:text-white lg:space-y-5 lg:order-4 order-3 px-4 py-6 space-y-4 rounded-lg">
							<div class="lg:pt-6 pt-2">
								<img class="lg:h-28 group-even:invert w-auto h-20 mx-auto" src="@/assets/images/aboutus-img05.png"
									alt="Price Friendly " width="111" height="112">
							</div>
							<div class="lg:text-2xl">Price Friendly</div>
						</div>
					</div>

				</div>
			</div>
		</div>

		<div class="section happyhour-section">
			<div class="lg:py-8 container pt-4 pb-12 text-white">
				<div class="lg:gap-8 lg:grid-cols-2 grid items-center">
					<img class="max-h-[482px] lg:max-h-[none] w-auto mx-auto" src="@/assets/images/happyhour-img01.png"
						alt="Happy Hour" width="608" height="854">
					<div class="space-y-8">
						<div class="neon-yellow lg:text-7xl space-y-1 text-2xl font-light leading-tight text-center">
							<div class="font-semibold">All Day Happy Hour</div>
							<div>From Now Till 31 May 2025</div>
						</div>
						<div class="sm:grid-cols-2 lg:pr-4 lg:pt-8 grid items-stretch gap-8">

							<div class="">
								<div class="bg-[rgba(99,99,99,0.55)] p-4 rounded-lg">
									<img class="object-contain w-full h-32 max-w-[16rem] px-4 mx-auto lg:max-w-none"
										src="@/assets/images/happyhour-carlsberg-logo.png" alt="Carlsberg logo" width="329" height="128">
									<div class="text-2xl leading-none">
										<div class="font-semibold">Twin Towers</div>
										<div class="font-light">Special</div>
									</div>
									<div class="">
										<div class="mt-3">
											<span class="text-siteRed text-8xl font-semibold leading-none">$94</span> <span
												class="text-[22px]">Only*</span>
										</div>
										<div class="text-lg">&nbsp;</div>
									</div>
									<div class="lg:-mt-28 xl:-mb-16 xl:-mt-40 lg:-mr-8 -mt-40 -mb-12">
										<img class="h-52 lg:h-40 xl:h-56 w-auto ml-auto" src="@/assets/images/happyhour-carlsberg-tap.png"
											alt="Carlsberg" width="134" height="224">
									</div>
								</div>
								<div class="px-4 mt-2 font-light">&nbsp;</div>
							</div>

							<div class="">
								<div class="bg-[rgba(99,99,99,0.55)] p-4 rounded-lg">
									<img class="object-contain w-full h-32 max-w-[16rem] px-4 mx-auto lg:max-w-none"
										src="@/assets/images/happyhour-1664-logo.png" alt="1664 logo" width="130" height="128">
									<div class="text-2xl leading-none">
										<div class="font-semibold">1664 Blanc</div>
										<div class="font-light">Tap</div>
									</div>
									<div class="">
										<div class="mt-3">
											<span class="text-siteRed text-8xl font-semibold leading-none">$56</span> <span
												class="text-[22px]">/Tower</span>
										</div>
										<div class="text-lg">&nbsp;</div>
									</div>
									<div class="lg:-mt-28 xl:-mb-16 xl:-mt-40 lg:-mr-8 -mt-40 -mb-12">
										<img class="h-52 lg:h-40 xl:h-56 w-auto ml-auto" src="@/assets/images/happyhour-1664-tap.png"
											alt="1664" width="97" height="224">
									</div>
								</div>
							</div>

						</div>

						<div class="pt-4 space-y-2 text-center">
							<div class="text-2xl lg:text-[34px] uppercase font-semibold">While Stock Last!!!</div>
							<div class="lg:text-lg">*All beer promotions are not available on Eve and Public holiday</div>
							<div class="lg:text-lg">* Terms & condition applies</div>
						</div>

					</div>
				</div>
			</div>
		</div>

		<div class="section menus-section">
			<div class="lg:pt-16 container pt-8 text-center">
				<div class="space-y-4">
					<div class="lg:text-6xl lg:leading-tight text-2xl">Our Wide Selection <span class="inline-block">Of Food &
							Drinks!</span></div>
					<div class="lg:text-2xl">In Wine there is Wisdom, <span class="inline-block">in Beer there is Freedom,</span>
						<span class="inline-block">In The Carpenters Bar there is Everything</span>
					</div>
					<div class="lg:text-4xl text-siteRed text-xl font-semibold">
						<span class="lg:inline-block block">“Happy Hour All Night Long!”</span>
					</div>
				</div>
				<div class="lg:mt-14 lg:text-lg flex flex-wrap justify-center mt-8 text-black">
					<a href="/pdf/tbc-full-menu.pdf" target="_blank"
						class="px-6 py-3 mr-4 mb-4 text-white uppercase duration-200 bg-black rounded-full sm:tracking-[.2em] lg:px-14 lg:py-4 hover:bg-siteYellow hover:text-black">
						All Day Menu
					</a>
				</div>
				<div class="mt-8">
					<iframe class="mx-auto w-full max-w-[420px]" width="420" scrolling="yes" height="950" frameborder="0"
						style="visibility: visible;" onload="this.style.visibility = 'visible';" allowtransparency="true"
						src="https://cho.pe/dineatthecarpentersbarsg"></iframe>
				</div>
			</div>

			<div class="relative overflow-hidden">
				<carousel class="lg:mt-16 menus-slider mt-8" :dots="false" :nav="false" :loop="true" :autoplay="true"
					:autoHeight="false" :autoplayTimeout="5000" :touchDrag="false" :mouseDrag="false" :margin="16" :responsive="{
						0: {items: 1},
						640: {items: 2},
						1024: {items: 4},
						1280: {items: 4, margin: 32},
					}">
					<div class="slide duration-500">
						<img class="slide-img object-cover duration-500" src="@/assets/images/menus-img01.jpg" alt="Menu Image 01"
							width="436" height="560">
					</div>

					<div class="slide duration-500">
						<img class="slide-img object-cover duration-500" src="@/assets/images/menus-img02.jpg" alt="Menu Image 03"
							width="436" height="560">
					</div>

					<div class="slide duration-500">
						<img class="slide-img object-cover duration-500" src="@/assets/images/menus-img03.jpg" alt="Menu Image 03"
							width="436" height="560">
					</div>

					<div class="slide duration-500">
						<img class="slide-img object-cover duration-500" src="@/assets/images/menus-img04.jpg" alt="Menu Image 04"
							width="436" height="560">
					</div>

					<div class="slide duration-500">
						<img class="slide-img object-cover duration-500" src="@/assets/images/menus-img05.jpg" alt="Menu Image 04"
							width="436" height="560">
					</div>

					<div class="slide duration-500">
						<img class="slide-img object-cover duration-500" src="@/assets/images/menus-img06.jpg" alt="Menu Image 04"
							width="436" height="560">
					</div>

					<div class="slide duration-500">
						<img class="slide-img object-cover duration-500" src="@/assets/images/menus-img07.jpg" alt="Menu Image 04"
							width="436" height="560">
					</div>

					<div class="slide duration-500">
						<img class="slide-img object-cover duration-500" src="@/assets/images/menus-img08.jpg" alt="Menu Image 04"
							width="436" height="560">
					</div>

					<div class="slide duration-500">
						<img class="slide-img object-cover duration-500" src="@/assets/images/menus-img09.jpg" alt="Menu Image 04"
							width="436" height="560">
					</div>

					<template slot="prev">
						<div class="bottom-48 absolute z-10 w-full pointer-events-none">
							<div class="container">
								<button
									class="mr-auto bg-[rgba(236,218,65,.75)] hover:bg-[rgb(236,218,65)] duration-200 w-16 h-16 p-4 rounded-full flex justify-center items-center xcursor-pointer pointer-events-auto">
									<img class="rotate-180" src="@/assets/images/arrow.png" alt="Previous" width="32" height="16">
								</button>
							</div>
						</div>
					</template>
					<template slot="next">
						<div class="bottom-48 absolute z-10 w-full pointer-events-none">
							<div class="container">
								<button
									class="ml-auto bg-[rgba(236,218,65,.75)] hover:bg-[rgb(236,218,65)] duration-200 w-16 h-16 p-4 rounded-full flex justify-center items-center xcursor-pointer pointer-events-auto">
									<img class="" src="@/assets/images/arrow.png" alt="Next" width="32" height="16">
								</button>
							</div>
						</div>
					</template>

				</carousel>
			</div>
		</div>

		<div class="section contact-section bg-textured">
			<div class="lg:py-16 container py-8 text-white">
				<div class="lg:grid-cols-2 lg:gap-16 grid gap-8">

					<div class="space-y-4">
						<div class="lg:text-2xl font-bold leading-tight">We Are Located At</div>
						<div class="lg:text-lg leading-loose">
							<span class="block">28 Woodlands Sector 2, <span class="inline-block">Singapore 737686</span></span>
							<span class="block">(Inside Woodlands Connection Building)</span>
						</div>
						<iframe class="rounded-lg"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2820.310102442275!2d103.80671720281343!3d1.4612698885985096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1741b4abe3cb%3A0x8533df48db4f57de!2sThe%20Carpenters%20Bar!5e0!3m2!1sen!2sph!4v1659546660281!5m2!1sen!2sph"
							width="100%" height="290" style="border:0;" allowfullscreen="" loading="lazy"
							referrerpolicy="no-referrer-when-downgrade"></iframe>
					</div>

					<div class="space-y-4">
						<div class="lg:text-2xl font-bold leading-tight">Reservations</div>
						<div class="xl:grid-cols-3 lg:text-lg grid grid-cols-2 leading-loose">
							<div class="hidden">Telephone</div>
							<div class="xl:col-span-2 hidden">
								: <a class="hover:underline" href="tel:+6569082931"><span>6908 2931</span></a>
							</div>
							<div>WhatsApp</div>
							<div class="xl:col-span-2">
								: <a class="hover:underline" href="https://wa.me/+6596502110?text=I%20would%20like%20to%20enquire"
									target="_blank"><span>9650 2110</span></a>
							</div>
						</div>
						<div class="flex py-4 space-x-4">
							<a class="rounded-full" href="https://www.facebook.com/thecarpentersbar/" target="_blank">
								<img class="w-14 h-14 rounded-full" src="@/assets/images/icon-fb.png" alt="Facebook" width="56"
									height="56">
							</a>
							<a class="rounded-full" href="https://instagram.com/thecarpentersbar/" target="_blank">
								<img class="w-14 h-14 rounded-full" src="@/assets/images/icon-ig.png" alt="Instagram" width="56"
									height="56">
							</a>
						</div>
						<div class="lg:text-2xl font-bold leading-tight">Opening Hours</div>
						<div class="xl:grid-cols-3 lg:text-lg grid grid-cols-2">
							<div class="py-2">Mon – Wed</div>
							<div class="xl:col-span-2 py-2">
								: 12pm – 10.30pm
							</div>
							<div class="py-2">Thurs – Sat</div>
							<div class="xl:col-span-2 py-2">
								: 12pm – 12am
							</div>
							<div class="py-2">Sundays & PH</div>
							<div class="xl:col-span-2 py-2">
								: Closed
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>

		<SiteFooter />

	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
// import ContactForm from '@/components/ContactForm.vue'

export default {
	name: 'index',
	components: {
		SiteHeader,
		SiteFooter,
		// ContactForm,
	},
	data() {
		return {
			isOpen: 0,
		}
	},
	mounted () {
		this.$modal.show('promo-popup')
	},
}
</script>

<style>
	/* .mainbanner-section {
		background-image: url('~@/assets/images/mainbanner-bg01.jpg');
		@apply bg-cover bg-center bg-no-repeat;
	} */
	.happyhour-section {
		background-image: url('~@/assets/images/happyhour-bg01.jpg');
		@apply bg-cover bg-center bg-no-repeat;
	}
	.bg-textured {
		background-image: url('~@/assets/images/bg-textured.jpg');
		@apply bg-cover bg-center bg-no-repeat;
	}

	.neon-yellow {
		/* text-shadow: 0 0 2.5px #FFF, 0 0 5px #FFF, 0 0 7.5px #FFF, 0 0 10px #f3d50a, 0 0 15px #f3d50a, 0 0 20px #f3d50a, 0 0 27.5px #f3d50a, 0 0 37.5px #f3d50a; */
		text-shadow: 0 0 10px #f3d50a, 0 0 15px #f3d50a, 0 0 20px #f3d50a, 0 0 27.5px #f3d50a, 0 0 37.5px #f3d50a;
	}

	.header-slider .owl-dots {
		/* @apply space-x-2 !my-8 !text-left; */
		@apply space-x-2 !-mt-8 lg:!-mt-16 relative;
		z-index: 2;
	}

	.header-slider .owl-dots .owl-dot span {
		@apply !bg-white !duration-200;
	}

	.header-slider .owl-dots .owl-dot.active span,
	.header-slider .owl-dots .owl-dot:hover span {
		@apply !bg-siteYellow;
	}

	.menus-slider>[id^="carousel_prev"] {
		display: block !important;
	}

	.vm--modal {
		background-color: transparent !important;
		box-shadow: none !important;
		padding: 16px;
		margin-top: -24px !important;
	}

	@screen lg {
		.menus-slider .owl-stage>.owl-item .slide {
			height: 560px;
			@apply pt-16;
			@apply overflow-hidden;
		}
		.menus-slider .owl-stage>.owl-item .slide-img {
			height: 496px;
			@apply origin-bottom;
			@apply saturate-0;
		}
		.menus-slider .owl-stage>.active ~ .active ~ .active:not(.active ~ .active ~ .active ~ .active) .slide {
			@apply pt-0;
		}
		.menus-slider .owl-stage>.active ~ .active ~ .active:not(.active ~ .active ~ .active ~ .active) .slide-img {
			height: 560px;
			@apply saturate-100;
		}
	}
	/* .menus-slider .owl-stage>.active ~ .active:not(.active ~ .active ~ .active) {
	} */
</style>
