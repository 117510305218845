<template>
	<header id="site-header" class="top-0 z-20 w-full text-white duration-200" :class="isTop ? 'py-4 lg:py-6 absolute' : 'py-2 fixed bg-black shadow-md'">
		<div class="container">
			<div class="lg:flex-nowrap flex flex-wrap items-center justify-center" :class="isTop ? 'sm:justify-between' : 'lg:justify-between'">
				<div class="gap-x-4 flex items-center">
					<img class="logo lg:mb-0 w-auto mb-4" :class="isTop ? 'h-20 lg:h-[113px]' : 'h-10 hidden lg:block'" src="@/assets/images/logo.png" alt="The Carpenters Bar logo" width="264" height="204">
					<div class="lg:block lg:text-2xl font-gothammedium flex-none hidden" v-show="!isTop">
						The Carpenters Bar
					</div>
				</div>
				<div class="lg:flex-nowrap flex flex-wrap justify-center border-white" :class="{'divide-x': !isTop}">
					<div class="sm:px-4 gap-x-2 sm:gap-x-4 flex px-3">
						<img class="w-auto h-8" :class="isTop ? 'lg:h-[50px]' : 'lg:h-10'" src="@/assets/images/icon-whatsapp.png" alt="WhatsApp" width="89" height="90">
						<div class="sm:block flex items-center">
							<div class="sm:block hidden text-xs" v-show="isTop">Text Or Whatsapp Us At</div>
							<a class="lg:text-2xl font-gothammedium hover:underline text-lg" href="https://wa.me/+6596502110?text=I%20would%20like%20to%20enquire" target="_blank">9650 2110</a>
						</div>
					</div>
					<div class="sm:px-4 gap-x-2 sm:gap-x-4 flex hidden px-3">
						<img class="w-auto h-8" :class="isTop ? 'lg:h-[50px]' : 'lg:h-10'" src="@/assets/images/icon-phone.png" alt="Phone" width="73" height="74">
						<div class="sm:block flex items-center">
							<div class="sm:block hidden text-xs" v-show="isTop">Call Us At</div>
							<a class="lg:text-2xl font-gothammedium hover:underline text-lg" href="tel:+6569082931"><span>6908 2931</span></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	data () {
		return {
			isTop: true,
		};
	},
	mounted () {
		document.addEventListener('scroll', this.handleScroll, {passive: true});
	},
	methods: {
		handleScroll() {
			if (window.pageYOffset >= 200) {
				if (this.isTop) this.isTop = false;
			} else if ( document.documentElement.scrollTop < 200 ) {
				if (!this.isTop) this.isTop = true;
			}
		},
	},
}
</script>

<style scoped>

</style>
